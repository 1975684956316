<template>
  <div class="upload">
    <p class="theme-color">住址证明</p>
    <div class="image-addr">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage"
        style="visibility: hidden"
        @change="selectFile($event)"
      />
      <label for="uploadImage">
        <van-image class="showimage-addr" :src="pic || defaultPic" />
      </label>
    </div>
    <div @click="downLoadPdf" v-if="pdf_name" class="pdf-name">{{ pdf_name }}</div>
    <div class="upload-tip theme-color">点击图片上传或更新（jpg/jpeg/png/pdf）</div>
    <div class="common-address-tip theme-color">
      <span>提示：</span><br />
      <span>1.住址证明必须与基本资料里填写的住址一致。</span><br />
      <span>2.住址证明可以是银行结单、水电费单或电话单。</span><br />
      <span>3.需要清晰显示姓名、住址详情。</span><br />
      <span>4.时间需要限定于近3个月内。</span><br />
      <span
        >5.示例（点击可查看）：<span
          class="link"
          @click="
            showPreview(
              'https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/temp_3address_01.jpg'
            )
          "
        >
          银行结单 </span
        >、<span
          class="link"
          @click="
            showPreview(
              'https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/temp_3address_02.jpg'
            )
          "
        >
          燃气单 </span
        >。</span
      ><br />
    </div>
  </div>
</template>

<script>
import { Uploader, Image as VanImage, ImagePreview } from "vant";
// import lrz from 'lrz';
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";
import uploadImage from "./uploadImage";
import util from "../../utils/util";

const defaultPdf =
  "https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/7_pdf.png";
const defaultImage =
  "https://bucket-client-jl.s3-ap-east-1.amazonaws.com/openAccount/image/3_live.png";
export default {
  name: "Home",
  components: {
    [Uploader.name]: Uploader,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      orientation: "",
      fileList: [],
      defaultPdf,
      defaultPic: defaultImage
    };
  },
  methods: {
    downLoadPdf() {
      // this.$api.postDownLoadFile(
      //   '/messenger/openAccount/downloadPdf.json',
      //   { type: 'addr_pdf' },
      //   'wj.pdf',
      // );
    },
    showPreview(key) {
      ImagePreview([key]);
    },
    getRotate(orientation) {
      if (orientation === 6) {
        return 90;
      }
      if (orientation === 8) {
        return 270;
      }
      if (orientation === 3) {
        return 180;
      }
      return 0;
    },
    async selectFile(event) {
      const file = event.target.files[0];
      if (file.type === "application/pdf") {
        const form = new FormData();
        form.append("file", file, this.$util.setPdfName(file.name));
        const result = await this.$api.uploadPdf("addr_pdf", form);
        const { data = {} } = (result || { data: {} }).data;
        if (data.fileName.endsWith(".pdf")) {
          this.pic = defaultPdf;
          this.pdf_name = this.$util.formatPdfName(data.fileName);
        }
      } else {
        uploadImage(file, "addr_photo", photo => {
          this.pic = photo;
          this.pdf_name = "";
        });
      }
    },
    before() {
      this.$router.go(-1);
    }
  },
  setup() {
    const pic = ref("");
    const pdf_name = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "上传资料");
      store.commit("setStepsShow", false);
      const result = await httpApi.getUploadImgesByType({ type: "addr_photo" });
      if (!result) return;
      const { value } = result.data;
      if (!value) return;
      if (value.endsWith(".pdf")) {
        pic.value = defaultPdf;
        pdf_name.value = util.formatPdfName(value);
      } else {
        pic.value = value;
      }
    });
    return {
      pic,
      pdf_name
    };
  }
};
</script>

<style lang="less">
.image-addr {
  width: 300px;
  height: 200px;
  margin: auto;
  position: relative;
}
.showimage-addr {
  position: absolute;
  width: 300px;
  height: 200px;
  top: 0;
  left: 0;
}
.pdf-name {
  margin: auto;
  text-align: center !important;
  margin: 20px;
}
.upload-tip {
  text-align: center !important;
  color: @font-color-2;
  margin-top: @space-2;
}
.common-address-tip {
  margin-top: 10px;
  color: @font-color-1;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
